import PropTypes from 'prop-types'
import React from 'react'
import jbentosc from '../images/jbento-sc_960x320.png'
import skills from '../images/skills_site.jpg'
import anuel from '../images/anuel_llnm.jpg'
import ss from '../images/ss_eolts.jpg'
// import jeff from '../images/jeff.jpg'


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          {/* <span className="image main">
            <img src={jeff} alt="" />
          </span> */}
          <p>
            I'm Jeffrey Johnson, a Site Reliability Engineer based in downstate New York.
            I graduated with my Bachelor of Arts degree in Computer Science from SUNY Oswego.
          </p>
          <h4>Programming Languages</h4>
          <ul>
            <li>Java</li>
            <li>Go</li>
            <li>Python</li>
          </ul>
          <h4>Cloud Technologies</h4>
          <ul>
            <li>Amazon Web Services (S3, DynamoDb, ECS, ECR CodeBuild, CodeDeploy, etc.)</li>
            <li>Terraform</li>
            <li>Cloudflare</li>
            <li>Boto3 (AWS CLI Wrapper Library for Python)</li>
          </ul>

          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>

          <span className="image main">
            <img src={skills} alt="" />
          </span>
          <h3 href="">Merchandising API</h3>
          <p>For the bulk of my internship at Splice, I was tasked with converting their Merchandising API from REST to their new RPC architecture.
            This API is currently used by their educational music production product Splice Skills. 
          </p>

          <h4>Project Overview</h4>
          <ul>
            <li>Defined Protocol Buffer messages that were used in our codegen script to automatically create the RPC handlers and models</li>
            <li>Implemented 12 RPC endpoints that were responsible for CRUD operations on various models stored in an MySQL database</li>
            <li>Collaborated with senior engineers and management to determine best patterns and revisit previous design decisions</li>
            <li>Tested the API changes in staging and production environments with Curl and Postman</li>
          </ul>

          <h4>Technologies Used</h4>
          <ul>
            <li>Protocol Buffers</li>
            <li>Twirp (Twitch RPC Framework)</li>
            <li>Go</li>
            <li>Protoc (Protocol Buffer Compiler)</li>
            <li>Curl</li>
            <li>Postman</li>
          </ul>

          <form action="https://splice.com/skills">
            <input type="submit" target="_blank" value="Splice Skills" /></form>

          <span className="image main">
            <img src={jbentosc} alt="" />
          </span>
          <h3 href="http://www.jbentobeats.com">JBento (2020)</h3>
          <p>JBento is a music brand that I created to sell Hip-Hop instrumentals to artists. 
            The site allows the user to purchase instrumentals through an embedded widget.
            The site was created with Bootstrap 4. Visitor information and statistics are collected using Google Analytics.
            It also collects information via Facebook Pixel for re-marketing purposes (Facebook and Instagram Ads).            
          </p>
          <h4>Technologies Used</h4>
          <ul>
            <li>Bootstrap 4 (HTML, CSS, JavaScript)</li>
            <li>Google Analytics</li>
            <li>Facebook Pixel</li>
            <li>MailerLite (Email list capture and signup)</li>
          </ul>

          <form action="jbentobeats.com">
            <input type="submit" target="_blank" value="Visit JBento" /></form>

          <h3 href="">Soju (2020)</h3>
          <p>Soju is an API that I designed an implemented for a liquor store. 
            It allows users to create accounts, add and delete beverages, using HTTP requests. 
            MySQL was used to query and persist data. The project was implemented in multiple frameworks to reinforce skills 
            and gain exposure to new technologies.
          </p>
          <h4>Technologies Used</h4>
          <ul>
            <li>Java</li>
            <li>Java EE (Raw Servlet API)</li>
            <li>Spark Java (microframework)</li>
            <li>Dropwizard</li>
            <li>Grails</li>
          </ul>

          <form action="https://github.com/jj-28/CSC-482-Software-Deployment/">
            <input type="submit" target="_blank" value="GitHub" /></form>


          <h3>Better YouTube API(2020)</h3>
          <p> API written in Golang that continuously polls YouTube Channel statistics using the official YouTube API. 
            Containerized using Docker, runs on an EC2 instance, stores data in a DynamoDb table. 
            Also sanitizes URLs to prevent XSS attacks.
          </p>
          <h4>Technologies Used</h4>
          <ul>
            <li>Go</li>
            <li>Docker</li>
            <li>YouTube Developer API</li>
            <li>BlueMonday (Sanitization Package)</li>
            <li>Gorilla Mux (HTTP Request Routing Package)</li>
          </ul>

          <h4>AWS Technologies Used</h4>
          <ul>
          <li>CodeDeploy</li>
            <li>CodeBuild</li>
            <li>S3</li>
            <li>ECR</li>
            <li>ECS</li>
            <li>Fargate</li>
          </ul>
          <form action="https://jbentobeats.com">
            <input type="submit" target="_blank" value="GitHub" /></form>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <p>
          When I'm not programming, I love to make music. I currently produce music under the name JBento.
          </p>

          <h3 className="major">Notable Selections From Disc.</h3>

          <span className="image main">
            <img src={anuel} alt="" />
          </span>
          <h4>"1942"- Las Leyendas Nunca Mueren- Anuel AA</h4>
          <ul>
            <li>#1 on Latin Rhythm Albums Chart</li>
            <li>#1 on Top Latin Albums</li>
            <li>#30 on Billboard 200</li>
            <li>#39 on Top Latin Songs</li>
            <li>#1 Album on ITunes Music in 17 countries</li>
            </ul>

            <form action="https://genius.com/Anuel-aa-1942-lyrics">
            <input type="submit" target="_blank" value="Genius" /></form>


            <span className="image main">
            <img src={ss} alt="" />
          </span>
          <h4>"The Number You Have Dialed Is Not In Service"- The Effects Of Long Term Suffering- $uicideboy$</h4>
          <ul> 
            <li>#7 on Billboard 200</li>
            <li>22 Million streams on Spotify</li>
            <li>#1 on Spotify Global Album Debuts</li>
          </ul>
            <form action="https://genius.com/Uicideboy-the-number-you-have-dialed-is-not-in-service-lyrics">
            <input type="submit" target="_blank" value="Genius" /></form> 
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://linkedin.com/jeffreyjohnsonswe"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/jeffjswe" className="icon fa-twitter">
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/jj-28"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
